import { isMobileOnly } from 'react-device-detect'

export const UNIT = 8

export const getSpacing = (multiplier = 1) => {
  const unit = UNIT || 8

  return unit * multiplier
}

export const lightTheme = {
  colors: {
    yellow: '#FCD430',
    black: '#121212',
    backgroundBlue: '#02072C',
    darkBlue: '#0F5EB9',
    blue: '#1871C9',
    lightBlue: '#2684D1',
    white: '#FFFFFF',
    bg: '#FAFAFA',
    red: '#EF443B',
    buttonBlue: '#021A69',
    grey: '#C5C8CA',
    lightGrey: '#F8F9FA'
  },
  fonts: {
    main: ['Montserrat', 'sans-serif'],
    defaultSize: 16
  },
  typography: {
    h1: {
      fontWeight: 900,
      fontSize: isMobileOnly ? 32 : 64,
      lineHeight: '125%',
      margin: 0,
      textAlign: 'center',
      fontFamily: 'Montserrat'
    },
    h2: {
      fontWeight: isMobileOnly ? 500 : 900,
      fontSize: isMobileOnly ? 20 : 30,
      lineHeight: '125%',
      margin: 0,
      textAlign: 'center',
      fontFamily: 'Montserrat'
    },
    h3: {
      fontWeight: isMobileOnly ? 500 : 900,
      fontSize: isMobileOnly ? 14 : 24,
      lineHeight: '125%',
      margin: 0,
      textAlign: 'center',
      fontFamily: 'Montserrat'
    },
    h4: {
      fontWeight: 600,
      fontSize: isMobileOnly ? 13 : 24,
      lineHeight: '125%',
      margin: 0,
      textAlign: 'center',
      fontFamily: 'Montserrat'
    },
    h5: {
      fontWeight: 500,
      fontSize: isMobileOnly ? 12 : 18,
      lineHeight: '125%',
      margin: 0,
      textAlign: 'center',
      fontFamily: 'Montserrat'
    },
    h6: {
      fontWeight: 600,
      fontSize: isMobileOnly ? 16 : 16,
      lineHeight: '125%',
      margin: 0,
      textAlign: 'center',
      fontFamily: 'Montserrat'
    },
    title: {
      fontWeight: 500,
      fontSize: isMobileOnly ? 24 : 36,
      lineHeight: '125%',
      margin: 0,
      fontFamily: 'Montserrat'
    },
    button: {
      fontWeight: 700,
      fontSize: isMobileOnly ? 14 : 16,
      lineHeight: '125%',
      fontFamily: 'Montserrat'
    },
    body: {
      fontWeight: 400,
      fontSize: isMobileOnly ? 8 : 16,
      lineHeight: '125%',
      fontFamily: 'Montserrat'
    },
    link: {
      fontWeight: 400,
      fontSize: isMobileOnly ? 14 : 14,
      lineHeight: '125%',
      fontFamily: 'Montserrat'
    },
    description: {
      fontWeight: 400,
      fontSize: isMobileOnly ? 11 : 12,
      lineHeight: '125%',
      fontFamily: 'Montserrat'
    },
    footerTitle: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '125%',
      margin: 0,
      textAlign: 'center',
      fontFamily: 'Montserrat'
    }
  },
  spacing: getSpacing,
  boxShadow:
    '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
  link: {
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '.25em 1em'
  },
  mediumScreen: '992px',
  largeScreen: '1438px'
}

export type Color = keyof typeof lightTheme.colors

type LightType = typeof lightTheme

export interface LightTheme extends LightType {}
