import React, { useMemo, useRef, useState } from 'react'

import { concatClassNames } from '../../utils'

import { Column } from '../column'
import { ArrowDown, World } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { DropdownItem, DropdownProps } from './dropdown.types'

import { useStyle } from './dropdown.styles'
import { useOutsideAlert } from '../../hooks'

export const Dropdown = <T extends string>({
  className,
  data,
  value,
  width = 200,
  onChange
}: DropdownProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, changeOpen] = useState(false)
  const classes = useStyle({ width })

  const Value = useMemo(() => {
    const currentItem = data.find((item) => item.value === value?.value)

    return currentItem || data[0]
  }, [value, data])

  const handleOnOpen = (state?: boolean) => () => {
    if (typeof state === 'undefined') {
      return changeOpen(!open)
    }

    changeOpen(state)
  }

  const handleOnChange = (item: DropdownItem<T>) => () => {
    changeOpen(false)

    if (onChange) {
      onChange(item)
    }
  }

  useOutsideAlert(ref, open, handleOnOpen(false))

  return (
    <Column className={concatClassNames(className)} ref={ref}>
      <Row fullWidth className={classes.top} onClick={handleOnOpen()}>
        <World />
        {Value && (
          <Text
            color="white"
            className={classes.text}
            preset="h6"
            text={Value.name}
            tx={Value.nameTx}
          />
        )}
        <ArrowDown className={classes.arrow} />
      </Row>
      {open && (
        <Column className={classes.content}>
          {data.map((item) => (
            <Row
              fullWidth
              key={`dropdown_item_${item.id || item.value}`}
              onClick={handleOnChange(item)}
              className={classes.row}
            >
              <Text
                color="white"
                preset="h6"
                text={item.name}
                tx={item.nameTx}
              />
            </Row>
          ))}
        </Column>
      )}
    </Column>
  )
}
