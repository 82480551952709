import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CategoryProducts, CategoryProductsProps } from '../../blocks'
import { CategoryCreators, ProductCreators, State } from '../../store'
import { Routes } from '../../routes/main.types'
import { useLocale } from '../../providers'

export const AllGadgets = () => {
  const history = useHistory()
  const { currentLocale } = useLocale()
  const { isLoaded, categories, categoryProducts } = useSelector(
    (state: State) => ({
      ...state.category,
      ...state.product
    })
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      CategoryCreators.getCategoriesRequest({
        params: { locale: currentLocale }
      })
    )
    dispatch(
      ProductCreators.getProductsRequest({ params: { locale: currentLocale } })
    )
  }, [currentLocale])

  const loading = !isLoaded || !categoryProducts

  const handleOnNavigate: CategoryProductsProps['onClick'] = (categoryId) => {
    if (!categoryId) {
      return history.push(Routes.PRODUCTS)
    }
    history.push(`${Routes.CATEGORY}/${categoryId}`)
  }

  const categoriesData = categories?.data

  return (
    <CategoryProducts
      id={undefined}
      categories={categoriesData}
      loading={loading}
      products={categoryProducts}
      title="All Products"
      titleTx="category.type.all"
      onClick={handleOnNavigate}
    />
  )
}
