import React, { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { CategoryProducts, CategoryProductsProps } from '../../blocks'
import { CategoryCreators, State } from '../../store'
import { Routes } from '../../routes/main.types'

import { GadgetCardParams } from './gadgets.types'
import { useLocale } from '../../providers'

export const GadgetPage = () => {
  const history = useHistory()
  const { isLoaded, categories, data, categoryProducts } = useSelector(
    (state: State) => ({
      ...state.category,
      ...state.product,
      categoriesIsLoaded: state.category.isLoaded
    })
  )
  const dispatch = useDispatch()
  const { currentLocale } = useLocale()
  const { id } = useParams<GadgetCardParams>()
  const ref = useRef(currentLocale)

  useEffect(() => {
    if (!id) {
      return
    }

    const query = {
      id
    }
    dispatch(
      CategoryCreators.getCategoryByIdRequest({
        query,
        params: { locale: currentLocale }
      })
    )
    dispatch(
      CategoryCreators.getCategoriesRequest({
        params: { locale: currentLocale }
      })
    )
  }, [id, currentLocale])

  useEffect(() => {
    if (ref.current !== currentLocale) {
      history.push(Routes.PRODUCTS)
      ref.current = currentLocale
    }
  }, [currentLocale])

  const loading = !isLoaded || !categoryProducts || !data

  const handleOnNavigate: CategoryProductsProps['onClick'] = (categoryId) => {
    if (!categoryId) {
      return history.push(Routes.PRODUCTS)
    }
    history.push(`${Routes.CATEGORY}/${categoryId}`)
  }

  const name = data?.attributes.name || 'Loading'
  const categoriesData = categories?.data

  return (
    <CategoryProducts
      id={id}
      categories={categoriesData}
      loading={loading}
      products={categoryProducts}
      title={name}
      onClick={handleOnNavigate}
    />
  )
}
