import React from 'react'
import { ThemeProvider } from 'react-jss'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { LightTheme, lightTheme } from './theme'
import { LocaleProvider } from './providers'
import createStore from './store'
import { MainRouter } from './routes'
import './App.css'

const store = createStore()

function App() {
  const theme: LightTheme = lightTheme

  return (
    <BrowserRouter>
      <Provider store={store}>
        <LocaleProvider>
          <ThemeProvider theme={theme}>
            <MainRouter />
          </ThemeProvider>
        </LocaleProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default App
