import React, { SVGProps } from 'react'

export const Email = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.714 2.714 5h18.572l.714.714v12.857l-.714.715H2.714L2 18.57V5.714Zm1.429 1.479v10.664H20.57V7.194l-8.128 6.235h-.872L3.43 7.193Zm15.757-.764H4.814L12 11.956l7.186-5.527Z"
      fill={fill || '#fff'}
    />
  </svg>
)
