import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import { Routes } from '../../routes/main.types'

import { Column } from '../column'
import { Content } from '../content'
import { Logo } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { Dropdown } from '../dropdown'

import { useStyle } from './burger.styles'
import { BurgerProps } from './burger.types'

export const Burger = forwardRef<HTMLDivElement, BurgerProps>(
  ({ locales, currentLocale, onChangeLanguage }, ref) => {
    const classes = useStyle()

    return (
      <Column fullWidth className={classes.container} ref={ref}>
        <Content>
          <Row wrap fullWidth className={classes.burger}>
            <Row className={classes.linkContainer}>
              <Link className={classes.link} to="/">
                <Text
                  className={classes.linkText}
                  preset="h6"
                  text="Home"
                  tx="header.home"
                  color="white"
                />
              </Link>
              <Link
                className={classes.link}
                to={Routes.CATEGORY}
                target="_self"
              >
                <Text
                  className={classes.linkText}
                  preset="h6"
                  text="Category"
                  tx="category.title"
                  color="white"
                />
              </Link>
              <Link
                className={classes.link}
                to={Routes.PRODUCTS}
                target="_self"
              >
                <Text
                  className={classes.linkText}
                  preset="h6"
                  text="Products"
                  tx="products.title"
                  color="white"
                />
              </Link>
              <Dropdown
                className={classes.locale}
                value={currentLocale}
                data={locales}
                onChange={onChangeLanguage}
                width={128}
              />
            </Row>
            <a className={classes.link} href="https://pgm.in.ua">
              <Logo className={classes.logo} />
            </a>
            <Row />
          </Row>
        </Content>
      </Column>
    )
  }
)
