import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { HomeBlock, CategoryBlock, GadgetsBlock } from '../../blocks'
import { HomeCreators, State } from '../../store'
import { Column, Text } from '../../components'
import { useLocale } from '../../providers'
import { useStyle } from './home.styles'

export const HomePage = () => {
  const { isLoaded, data } = useSelector((state: State) => ({
    isLoaded: state.home.isLoaded,
    data: state.home.homeData
  }))
  const dispatch = useDispatch()
  const { currentLocale } = useLocale()
  const classes = useStyle()

  useEffect(() => {
    dispatch(HomeCreators.getHomeRequest({ params: { locale: currentLocale } }))
  }, [currentLocale])

  if (!isLoaded || !data) {
    return <></>
  }

  const { description, mainBanner, categories, topProducts } =
    data.data.attributes
  const img = mainBanner?.data.attributes.url

  return (
    <Column>
      <HomeBlock img={img} />
      <Text
        className={classes.description}
        color="white"
        preset="title"
        text={description}
      />
      <CategoryBlock categories={categories} />
      <GadgetsBlock
        className={classes.gadgets}
        products={topProducts}
        title="Top Products"
        titleTx="home.topProducts"
      />
    </Column>
  )
}
