import React, { useEffect } from 'react'
import { useTheme } from 'react-jss'
import { Switch, Route } from 'react-router-dom'

import { Column, Footer, Header, PolygonBackground } from '../components'
import {
  GadgetPage,
  HomePage,
  ItemPage,
  CategoriesPage,
  AllGadgets
} from '../pages'
import { LightTheme } from '../theme'

import { useStyle } from './main.styles'
import { Routes } from './main.types'

export const MainRouter = () => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle()
  useEffect(
    () =>
      document.documentElement.style.setProperty(
        '--bodyColor',
        theme.colors.backgroundBlue
      ),
    []
  )
  return (
    <Column
      fullWidth
      fullHeight
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Header />
      <PolygonBackground>
        <Column
          fullWidth
          fullHeight
          className={classes.content}
          alignItems="normal"
          justifyContent="flex-start"
        >
          <Switch>
            <Route exact path={Routes.HOME}>
              <HomePage />
            </Route>
            <Route exact path={Routes.CATEGORY}>
              <CategoriesPage />
            </Route>
            <Route exact path={`${Routes.CATEGORY}/:id`}>
              <GadgetPage />
            </Route>
            <Route exact path={Routes.PRODUCTS}>
              <AllGadgets />
            </Route>
            <Route exact path={`${Routes.PRODUCT}/:id`}>
              <ItemPage />
            </Route>
          </Switch>
        </Column>
      </PolygonBackground>
      <Footer />
    </Column>
  )
}
