import React, { FC } from 'react'

import { concatClassNames } from '../../utils'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './gadget-card.styles'
import { GadgetCardProps } from './gadget-card.types'

export const GadgetCard: FC<GadgetCardProps> = ({
  className,
  currency,
  title,
  price,
  salePrice,
  images,
  info,
  isSale,
  onClick
}) => {
  const classes = useStyle({ isSale })
  return (
    <Column
      className={concatClassNames(className, classes.container)}
      justifyContent="top"
      onClick={onClick}
    >
      <Row fullWidth className={classes.row} justifyContent="center">
        <Text className={classes.title} preset="h4" text={title} />
      </Row>
      <div className={classes.imgBox}>
        <img className={classes.img} src={images?.data[0].attributes.url} />
      </div>
      <Text className={classes.description} preset="body" text={info} />
      <Text
        className={classes.price}
        preset="h4"
        text={`${price} ${currency}`}
      />
      {isSale && (
        <>
          <Text
            className={classes.description}
            color="red"
            preset="h3"
            text={`${salePrice} ${currency}`}
          />
          {/* <Column className={classes.imgSale}>
            <SaleIcon />
          </Column> */}
        </>
      )}
    </Column>
  )
}
