import React, { FC } from 'react'

import { PolygonBackground, Section } from '../../components'

import { useStyle } from './home.styles'
import { HomeBlockProps } from './home.types'

export const HomeBlock: FC<HomeBlockProps> = ({ img }) => {
  const classes = useStyle()

  return (
    <Section className={classes.section}>
      <PolygonBackground className={classes.block}>
        <img className={classes.homeImg} src={img} />
      </PolygonBackground>
    </Section>
  )
}
