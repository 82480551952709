import React, { FC } from 'react'

import { concatClassNames } from '../../utils'

import { Row } from '../row'

import { DotProps } from './dot.types'
import { useStyle } from './dot.styles'

export const Dot: FC<DotProps> = ({ active, className, size, onClick }) => {
  const classes = useStyle({ active, size })

  return (
    <Row
      className={concatClassNames(className, classes.container)}
      onClick={onClick}
    />
  )
}
