import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Row, GadgetCard, Column } from '../../components'
import { concatClassNames } from '../../utils'
import { Routes } from '../../routes/main.types'

import { useStyle } from './header-gadgets.styles'
import { HeaderGadgetsBlockProps } from './header-gadgets.types'

export const HeaderGadgetsBlock: FC<HeaderGadgetsBlockProps> = ({
  className,
  products
}) => {
  const history = useHistory()
  const classes = useStyle()

  const handleOnNavigate = (id: number) => () => {
    history.push(`${Routes.PRODUCT}/${id}`)
  }

  return (
    <Column
      alignItems="flex-start"
      className={concatClassNames(className, classes.buttonBlock)}
    >
      <Row wrap fullWidth className={classes.cards}>
        {products.data.map((card) => (
          <GadgetCard
            className={classes.card}
            isSale={card.attributes.isDiscount}
            key={`card_${card.id}`}
            currency={card.attributes.currency}
            title={card.attributes.name}
            images={card.attributes.images}
            info={card.attributes.previewDesctription}
            price={card.attributes.price}
            salePrice={card.attributes.discountPrice}
            onClick={handleOnNavigate(card.id)}
          />
        ))}
      </Row>
    </Column>
  )
}
