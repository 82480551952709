import React, { MouseEventHandler, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { burger } from '../../assets'
import { useLocale } from '../../providers'
import { Routes } from '../../routes/main.types'
import { ServerLocale } from '../../services'

import { Burger } from '../burger'
import { Column } from '../column'
import { Content } from '../content'
import { Dropdown, DropdownItem } from '../dropdown'
import { Logo } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import * as headerStyles from './header.styles'

export type DropdownLocaleOption = {
  label: string
  value: ServerLocale
}

export const Header = () => {
  const ref = useRef(null)
  const { currentLocale, changeLocale } = useLocale()
  const classes = headerStyles.useStyle()
  const [open, changeOpen] = useState(false)

  const handleOnOpen: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    changeOpen(!open)
  }

  const locales: DropdownItem<ServerLocale>[] = [
    {
      value: ServerLocale.EN,
      name: 'EN'
    },
    {
      value: ServerLocale.UK,
      name: 'UA'
    }
  ]

  const CurrentLocale = useMemo(() => {
    return locales.find((c) => c.value === currentLocale)
  }, [currentLocale, locales])

  const handleOnChangeLanguage = (nextValue: DropdownItem<ServerLocale>) => {
    if (changeLocale) {
      changeLocale(nextValue.value)
    }
  }

  return (
    <Column fullWidth className={classes.container}>
      <Content>
        <Row
          wrap
          fullWidth
          className={classes.burgerPos}
          justifyContent="space-between"
        >
          <a className={classes.link} href="https://pgm.in.ua">
            <Logo className={classes.logo} />
          </a>
          <img src={burger} className={classes.burger} onClick={handleOnOpen} />
          {open && (
            <Burger
              ref={ref}
              locales={locales}
              currentLocale={CurrentLocale}
              onChangeLanguage={handleOnChangeLanguage}
            />
          )}
          <Row className={classes.linkContainer}>
            <Link className={classes.link} to="/">
              <Text
                className={classes.linkText}
                preset="body"
                text="Home"
                tx="header.home"
                color="white"
              />
            </Link>
            <Link className={classes.link} to={Routes.CATEGORY} target="_self">
              <Text
                className={classes.linkText}
                preset="body"
                text="Category"
                tx="category.title"
                color="white"
              />
            </Link>
            <Link className={classes.link} to={Routes.PRODUCTS} target="_self">
              <Text
                className={classes.linkText}
                preset="body"
                text="Products"
                tx="products.title"
                color="white"
              />
            </Link>
          </Row>
          <Dropdown
            className={classes.locale}
            value={CurrentLocale}
            data={locales}
            onChange={handleOnChangeLanguage}
          />
        </Row>
      </Content>
    </Column>
  )
}
