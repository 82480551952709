import React, { FC } from 'react'

import { Column, Row, Text } from '../../components'

import { CategoryNavigation } from '../category-navigation'
import { HeaderGadgetsBlock } from '../header-gadgets'

import { useStyle } from './category-products.styles'
import { CategoryProductsProps } from './category-products.types'

export const CategoryProducts: FC<CategoryProductsProps> = ({
  id,
  categories,
  loading,
  products,
  title,
  titleTx,
  onClick
}) => {
  const classes = useStyle()

  const activeId = id ? Number(id) : undefined

  if (loading || !products || !categories) {
    return <></>
  }

  return (
    <Row
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
      className={classes.container}
    >
      <CategoryNavigation
        className={classes.navigation}
        activeId={activeId}
        categories={categories}
        onClick={onClick}
      />
      <Column fullWidth className={classes.gadgets} justifyContent="flex-start">
        <Text
          className={classes.title}
          preset="h1"
          color="white"
          text={title}
          tx={titleTx}
        />

        <HeaderGadgetsBlock className={classes.cards} products={products} />
      </Column>
    </Row>
  )
}
