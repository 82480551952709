import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  section: {
    display: 'flex'
  },
  block: {},
  homeImg: {
    width: '100%',
    objectFit: 'cover',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 1
  },
  '@media screen and (max-width: 440px)': {
    section: {
      display: 'none'
    }
  }
})
