import React, { FC } from 'react'

import { Row, Column, Text, Button, Carousel } from '../../components'

import { useStyle } from './header-item.styles'
import { ProductItemProps } from './header-item.types'

export const HeaderItemBlock: FC<ProductItemProps> = ({
  // className,
  product,
  email
}) => {
  const { name, previewDesctription, price, discountPrice, currency, images } =
    product.data.attributes
  const classes = useStyle()

  return (
    <Column fullWidth alignItems="center" justifyContent="flex-start">
      <Text color="bg" preset="h1" className={classes.title} text={name} />
      <Row wrap className={classes.cards}>
        <Column className={classes.left}>
          <Text
            className={classes.text}
            color="bg"
            preset="h4"
            text={previewDesctription}
          />
          <Column className={classes.info} alignItems="flex-end">
            <Row className={classes.price}>
              <Row className={classes.priceName}>
                <Text color="bg" preset="h3" text="Price:" />
              </Row>
              <Text
                className={classes.priceAtributte}
                color="bg"
                preset="h3"
                text={`${price} ${currency}`}
              />
            </Row>
            {discountPrice > 0 && (
              <Text
                className={classes.priceSale}
                color="red"
                preset="h3"
                text={`${discountPrice} ${currency}`}
              />
            )}
          </Column>
          <Row fullWidth>
            <a aria-disabled={!email} href={`mailto:${email}`}>
              <Button
                className={classes.button}
                disabled={!email}
                text="Contact Us"
                tx="footer.contact.title"
              />
            </a>
          </Row>
        </Column>
        <Column className={classes.right}>
          <Carousel infinity images={images} />
        </Column>
      </Row>
    </Column>
  )
}
