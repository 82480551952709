import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { DotStyleProps } from './dot.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: ({ active, size = 8 }: DotStyleProps) => ({
    backgroundColor: active ? theme.colors.lightGrey : theme.colors.grey,
    borderRadius: size,
    height: size,
    width: size
  })
}))
