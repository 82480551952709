import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  cards: {
    gap: 32,
    width: '100%',
    height: '100%'
  },
  buttonBlock: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {},
  '@media screen and (max-width: 440px)': {
    cards: {
      gap: 16
    }
  }
})
