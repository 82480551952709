import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { CarouselStyleProps } from './carousel.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  arrowContainer: {
    position: 'absolute',
    background: `linear-gradient(90deg, ${theme.colors.grey} 0%, rgba(255,255,255,0) 100%)`,
    inset: '0 auto 0 0',
    width: 60,
    opacity: 0.2,
    cursor: 'pointer',
    zIndex: 2,
    scrollBehavior: 'smooth',
    borderRadius: '8px 0px 0px 8px',
    '&:hover': {
      opacity: 1
    }
  },
  right: {
    inset: '0 0 0 auto',
    transform: 'rotate(180deg)'
  },
  carouselContainer: {
    borderRadius: 8
  },
  imageContainer: ({ height, width }: CarouselStyleProps) => ({
    borderRadius: 8,
    overflowX: 'hidden',
    maxHeight: height,
    maxWidth: width
  }),
  image: ({ height, width }: CarouselStyleProps) => ({
    objectFit: 'cover',
    height,
    minWidth: width,
    pointerEvents: 'none'
  }),
  dotContainer: {
    marginTop: 8
  },
  dot: {
    cursor: 'pointer',
    margin: 4
  },
  '@media screen and (max-width: 440px)': {
    imageContainer: {
      maxHeight: '220px !important',
      maxWidth: 'calc(100vw - 32px) !important'
    },
    image: {
      height: '220px !important',
      minWidth: '100% !important'
    }
  }
}))
