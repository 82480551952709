import * as React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { SVGIconProps } from './icon.types'

export const ArrowLeft = ({ size = 16, ...props }: SVGIconProps) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.774 4.8 6.574 8l3.2 3.2-.707.707L5.16 8l3.907-3.907.707.707Z"
        fill={props.fill || theme.colors.black}
      />
    </svg>
  )
}
