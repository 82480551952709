import React, { FC, MouseEvent } from 'react'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './category-card.styles'
import { CategoryCardProps } from './category-card.types'

export const CategoryCard: FC<CategoryCardProps> = ({
  title,
  img,
  onClick
}) => {
  const classes = useStyle()

  const handleOnClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <Column
      className={classes.container}
      justifyContent="top"
      onClick={handleOnClick}
    >
      <div className={classes.backGr}></div>
      <Row fullWidth className={classes.row} justifyContent="center">
        <Text preset="h3" text={title} />
      </Row>
      <img className={classes.img} src={img} />
    </Column>
  )
}
