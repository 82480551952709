import React, { FC } from 'react'

import { concatClassNames } from '../../../utils'

import { Text } from '../../text'

import { useStyle } from './footer-list-item.styles'
import { FooterListItemProps } from './footer-list-item.types'

export const FooterListItem: FC<FooterListItemProps> = ({
  className,
  icon,
  text,
  tx,
  link,
  outer
}) => {
  const classes = useStyle({ icon })

  const target = outer ? '_blank' : '_self'

  return (
    <a
      className={concatClassNames(className, classes.link)}
      href={link}
      target={target}
    >
      {icon ? icon : <></>}
      <Text
        className={classes.item}
        color="white"
        preset="link"
        text={text}
        tx={tx}
      />
    </a>
  )
}
