import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: 24,
    width: 'calc(100% - 48px)'
  },
  gadgets: {
    marginTop: 32
  },
  cards: {
    marginLeft: 20
  },
  title: {},
  '@media screen and (max-width: 440px)': {
    container: {
      padding: 16,
      width: 'calc(100% - 32px)'
    },
    gadgets: {
      marginLeft: 0
    },
    title: {
      marginTop: 16
    },
    cards: {
      marginLeft: 0
    }
  }
})
