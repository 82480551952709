import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: 24,
    width: 'calc(100% - 48px)'
  },
  navigation: {
    marginTop: 100
  },
  gadgets: {},
  cards: {
    marginLeft: 24,
    marginTop: 24
  },
  title: {},
  '@media screen and (max-width: 440px)': {
    container: {
      flexDirection: 'column',
      padding: 16,
      width: 'calc(100% - 32px)'
    },
    gadgets: {
      marginTop: 16
    },
    navigation: {
      marginTop: 0
    },
    title: {},
    cards: {
      marginLeft: 0,
      padding: '16px 0'
    }
  }
})
