import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Column } from '../../components'

import { ProductCreators, State } from '../../store'

import { InfoItemBlock, HeaderItemBlock } from '../../blocks'
// import { categoryReducer } from '../../store/reducers'

export type ItemCardParams = {
  id: string
}

export const ItemPage = () => {
  // const classes = useStyle()
  const { isLoaded, product, email } = useSelector((state: State) => ({
    ...state.product,
    email: state.footer.footerData?.data.attributes.email
  }))
  const dispatch = useDispatch()
  const { id } = useParams<ItemCardParams>()

  useEffect(() => {
    if (!id) {
      return
    }

    const query = {
      id
    }
    dispatch(
      ProductCreators.getProductByIdRequest({
        query
      })
    )
  }, [id])

  if (!isLoaded) {
    return <></>
  }

  if (!product) {
    return <></>
  }

  return (
    <Column fullWidth alignItems="center" justifyContent="flex-start">
      <HeaderItemBlock email={email} product={product} />
      <InfoItemBlock product={product} />
    </Column>
  )
}
