import { createUseStyles } from 'react-jss'

import { ColorDotStyle } from './color-dot.types'

const size = {
  big: 24,
  default: 16,
  normal: 8,
  mini: 4
}

export const useStyle = createUseStyles(() => ({
  container: ({ color = 'black', preset = 'default' }: ColorDotStyle) => ({
    background: color,
    borderRadius: '100%',
    height: size[preset],
    width: size[preset]
  })
}))

export type ColorDotSize = keyof typeof size
