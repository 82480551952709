import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    minHeight: 'calc(100vh - 120px)'
  }
  // '@media screen and (max-width: 440px)': {
  //   height: 10000
  // }
})
