import React, { FC } from 'react'

import { Button, Column } from '../../components'
import { Category, ItemWithAttributes } from '../../services'
import { concatClassNames } from '../../utils'

import { useStyle } from './category-navigation.styles'
import { CategoryNavigationProps } from './category-navigation.types'

export const CategoryNavigation: FC<CategoryNavigationProps> = ({
  activeId,
  categories,
  className,
  onClick
}) => {
  const classes = useStyle()

  const handleOnClick = (id?: ItemWithAttributes<any>['id']) => () => {
    if (onClick) {
      onClick(id)
    }
  }

  const getActive = (current?: ItemWithAttributes<Category>['id']) =>
    activeId === current ? classes.active : undefined

  return (
    <Column
      className={concatClassNames(className, classes.container)}
      justifyContent="flex-start"
    >
      <Button
        className={concatClassNames(classes.button, getActive(undefined))}
        text="All Products"
        tx="category.type.all"
        onClick={handleOnClick(undefined)}
      />
      {categories.map((category) => (
        <Button
          key={`category_${category.id}`}
          className={concatClassNames(classes.button, getActive(category.id))}
          text={category.attributes.name}
          onClick={handleOnClick(category.id)}
        />
      ))}
    </Column>
  )
}
