import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CategoryBlock } from '../../blocks'
import { Column } from '../../components'

import { CategoryCreators, State } from '../../store'

import { useStyle } from './categories.styles'
import { useLocale } from '../../providers'

export const CategoriesPage = () => {
  const { isLoaded, categories } = useSelector((state: State) => ({
    isLoaded: state.category.isLoaded,
    categories: state.category.categories
  }))
  const dispatch = useDispatch()
  const { currentLocale } = useLocale()
  useEffect(() => {
    dispatch(
      CategoryCreators.getCategoriesRequest({
        params: { locale: currentLocale }
      })
    )
  }, [currentLocale])

  const classes = useStyle()

  const loading = !isLoaded || !categories

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      {!loading && <CategoryBlock categories={categories} />}
    </Column>
  )
}
