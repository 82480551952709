import React, { FC } from 'react'

import { Column } from '../../column'
import { Text } from '../../text'

import { FooterListItem } from '../footer-list-item'
import { useStyle } from './footer-list.styles'

import { FooterListProps } from './footer-list.types'

export const FooterList: FC<FooterListProps> = ({ data, title, titleTx }) => {
  const classes = useStyle()

  return (
    <Column alignItems="flex-start">
      <Text color="white" preset="footerTitle" text={title} tx={titleTx} />
      {data.map((item, index) => (
        <FooterListItem
          key={`footer_list_item_${index}`}
          className={classes.item}
          {...item}
        />
      ))}
    </Column>
  )
}
