import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    width: 224,
    height: 80,
    borderRadius: 16,
    backgroundColor: theme.colors.buttonBlue,
    borderColor: theme.colors.buttonBlue,
    '&:not(:first-child)': {
      marginTop: 24
    }
  },
  active: {
    backgroundColor: theme.colors.lightBlue
  },
  container: {},
  '@media screen and (max-width: 440px)': {
    container: {
      flexDirection: 'row',
      padding: 16,
      overflowX: 'auto',
      width: 'calc(100% - 32px)'
    },
    button: {
      height: 48,
      width: 'auto',
      '&:not(:first-child)': {
        marginTop: 0,
        marginLeft: 8
      }
    }
  }
}))
