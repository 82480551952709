import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 352,
    height: 492,
    borderRadius: 16,
    overflow: 'hidden'
  },
  row: {
    backgroundColor: theme.colors.white,
    height: 72,
    zIndex: '1',
    position: 'relative'
  },
  img: {
    height: 420,
    objectFit: 'cover',
    zIndex: '1',
    position: 'relative'
  },
  backGr: {
    zIndex: '2',
    position: 'absolute',
    backgroundColor: 'rgba(217, 217, 217, 0.4)',
    width: '100%',
    height: '100%',
    transition: 'opacity 0.5s ease-in-out',
    '&:hover': {
      opacity: '0'
    }
  },
  '@media screen and (max-width: 440px)': {
    container: {
      width: 145,
      height: 177
    },
    row: {
      height: 32
    },
    img: {
      height: 145,
      width: 145
    }
  }
}))
