import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  block: {
    maxWidth: 1920,
    height: '100%',
    padding: '80px 77px 160px 77px',
    overflow: 'hidden'
  },
  text: {
    marginTop: '80px',
    alignItems: 'start',
    textAlign: 'left'
  },
  bg: {
    width: '100vw',
    background: theme.colors.backgroundBlue
  },

  '@media screen and (max-width: 440px)': {
    block: {
      width: '375px',
      height: '100%',
      padding: '24px 0px'
    },
    text: {
      marginTop: '16px',
      alignItems: 'start',
      textAlign: 'left',
      padding: '0px 34px'
    },
    bg: {
      minHeight: 390
    }
  }
}))
