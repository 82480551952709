import React, { SVGProps } from 'react'

export const Phone = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M17.465 22c-.195 0-.389-.022-.579-.065a19.49 19.49 0 0 1-9.62-5.18 19.486 19.486 0 0 1-5.21-9.619 2.77 2.77 0 0 1 .067-1.371 2.72 2.72 0 0 1 .715-1.162l2.315-2.28a1.153 1.153 0 0 1 .892-.319c.168.018.33.073.475.162.145.09.27.21.364.353l2.894 4.375a.963.963 0 0 1-.064 1.124L8.261 9.794a11.213 11.213 0 0 0 2.414 3.487 11.139 11.139 0 0 0 3.514 2.42l1.8-1.461a.937.937 0 0 1 1.077-.072l4.37 2.89c.15.09.277.216.373.365a1.202 1.202 0 0 1-.136 1.475l-2.257 2.285c-.257.261-.561.468-.896.608-.335.14-.693.211-1.055.209ZM5.952 3.176l-2.315 2.28a1.509 1.509 0 0 0-.401.65c-.077.25-.088.515-.034.77a18.291 18.291 0 0 0 4.868 9.033 18.334 18.334 0 0 0 9.053 4.872c.258.055.525.044.778-.033.253-.076.483-.216.67-.405l2.257-2.285-4.22-2.788-1.933 1.574a.573.573 0 0 1-.533.1 11.588 11.588 0 0 1-4.26-2.811 11.553 11.553 0 0 1-2.819-4.262.605.605 0 0 1 .14-.562l1.562-1.912-2.813-4.22Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={fill || '#fff'} d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
