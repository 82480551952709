import React, { FC, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { isMobileOnly } from 'react-device-detect'
import { useTheme } from 'react-jss'

import { concatClassNames } from '../../utils'
import { LightTheme } from '../../theme'

import { Row } from '../row'
import { Column } from '../column'
import { Dot } from '../dot'
import { ArrowLeft } from '../icon'

import { CarouselProps } from './carousel.types'
import { useStyle } from './carousel.styles'

export const Carousel: FC<CarouselProps> = ({
  className,
  infinity,
  images: data,
  height = 400,
  width = 550
}) => {
  const theme = useTheme<LightTheme>()
  const ref = useRef<HTMLDivElement>(null)
  const [active, changeActive] = useState(0)
  const classes = useStyle({ height, width })

  const images = data.data.slice(0, 15)

  const count = images.length

  const swipeableConfig = {
    delta: 20,
    preventDefaultTouchmoveEvent: false,
    trackTouch: isMobileOnly,
    trackMouse: true,
    rotationAngle: 0
  }

  const handleOnChangeActive = (index: number) => () => {
    const container = ref.current
    let nextActive = index
    const end = index < 0 || index > count - 1

    if (!infinity && end) {
      return
    }

    if (index > count - 1) {
      nextActive = 0
    }

    if (index < 0) {
      nextActive = count - 1
    }

    changeActive(nextActive)

    if (container) {
      setTimeout(() => {
        container.scrollLeft = container.clientWidth * nextActive
        container.scrollTo({
          left: container.clientWidth * nextActive,
          behavior: 'smooth'
        })
      }, 100)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleOnChangeActive(active + 1),
    onSwipedRight: handleOnChangeActive(active - 1),
    ...swipeableConfig
  })

  const handleOnRef = (element: HTMLDivElement) => {
    // @ts-ignore
    ref.current = element

    handlers.ref(element)
  }

  return (
    <Column justifyContent="flex-start">
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.carouselContainer}
      >
        {count > 1 && (
          <Row
            className={classes.arrowContainer}
            role="button"
            onClick={handleOnChangeActive(active - 1)}
          >
            <ArrowLeft fill={theme.colors.white} size={32} />
          </Row>
        )}
        <Row
          className={concatClassNames(className, classes.imageContainer)}
          justifyContent="flex-start"
          {...handlers}
          ref={handleOnRef}
        >
          {images.map((image) => (
            <img
              key={`carousel_item_${image.id}`}
              className={classes.image}
              src={image.attributes.url}
              alt={image.attributes.alternativeText || undefined}
            />
          ))}
        </Row>
        {count > 1 && (
          <Row
            className={concatClassNames(classes.arrowContainer, classes.right)}
            onClick={handleOnChangeActive(active + 1)}
          >
            <ArrowLeft fill={theme.colors.white} size={32} />
          </Row>
        )}
      </Column>
      <Row className={classes.dotContainer}>
        {count > 1 &&
          images.map((image, index) => (
            <Dot
              key={`dot_item_${image.id}_${index}`}
              active={index === active}
              className={classes.dot}
              onClick={handleOnChangeActive(index)}
            />
          ))}
      </Row>
    </Column>
  )
}
