import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  cards: {
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    maxWidth: '100vw',
    padding: 32,
    alignItems: 'flex-start',
    overflow: 'hidden'
  },
  title: {
    marginTop: 56
  },
  left: {
    alignSelf: 'stretch',
    flex: 1,
    justifyContent: 'start',
    alignItems: 'start'
  },
  info: {
    marginTop: 62
  },
  text: {
    textAlign: 'left'
  },
  price: {},
  priceAtributte: {
    marginLeft: 24
  },
  priceSale: {
    marginTop: '32px'
  },
  button: {
    marginTop: 48,
    width: 208,
    height: 64
  },
  priceName: {},
  right: {
    alignSelf: 'stretch',
    flex: 1,
    marginLeft: 16
  },
  img: {
    flex: 1,
    width: '100%',
    objectFit: 'cover'
  },
  '@media screen and (max-width: 440px)': {
    title: {
      width: 311,
      marginTop: 24
    },
    description: {
      marginTop: 16,
      width: 'calc(100% - 32px)'
    },
    cards: {
      justifyContent: 'center',
      width: 'calc(100% - 32px)',
      padding: '24px 16px',
      alignItems: 'center',
      flexDirection: 'column-reverse',
      flexWrap: 'nowrap'
    },
    left: {
      paddingRight: '0',
      justifyContent: 'center',
      alignItems: 'center'
    },
    text: {
      display: 'none'
    },
    right: {
      marginLeft: 0,
      height: '100%',
      objectFit: 'cover'
    },
    img: {
      width: '307px',
      height: '211px',
      objectFit: 'fill'
    },
    priceName: {
      display: 'none'
    },
    priceAtributte: {
      marginLeft: '0'
    },
    priceSale: {
      marginLeft: '0',
      marginTop: '17px'
    },
    info: {
      marginTop: '25px'
    },
    button: {
      marginLeft: 0,
      marginTop: 32,
      width: 120,
      height: 32
    }
  }
})
