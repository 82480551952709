import { createUseStyles } from 'react-jss'

import { GadgetCardStyleProps } from './gadget-card.types'

export const useStyle = createUseStyles({
  container: {
    backgroundColor: 'white',
    borderRadius: 16,
    height: 540,
    width: 352
  },
  row: {
    height: 62
  },
  img: {
    height: 320,
    width: 352,
    objectFit: 'cover'
  },
  imgBox: {},
  title: {
    padding: '16px 0'
  },
  imgSale: {
    position: 'absolute',
    bottom: 0,
    left: 'calc(100% - 36px)'
  },
  description: {
    marginTop: 8,
    width: 'calc(100% - 16px)',
    textAlign: 'center',
    height: 42,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  price: {
    marginTop: 16,
    textDecoration: ({ isSale }: GadgetCardStyleProps) =>
      isSale ? 'line-through' : 'none'
  },
  '@media screen and (max-width: 440px)': {
    container: {
      width: 145,
      height: 226
    },
    title: {
      padding: 4
    },
    row: {
      width: 145,
      height: 40
    },
    img: {
      height: 97,
      width: 145
    },
    descriptionTx: {
      marginTop: 8,
      width: 145,
      height: 20
    },
    imgSale: {
      display: 'none'
    },
    price: {
      marginTop: 8
    },
    imgBox: {
      height: 97,
      width: 145
    },
    description: {
      height: 20
    }
  }
})
