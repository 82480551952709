import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { BlockContainer, GadgetCard, Row, Text } from '../../components'

import { useStyle } from './gadgets-block.styles'
import { GadgetCardProps } from './gadgets-block.types'
import { concatClassNames } from '../../utils'

export const GadgetsBlock: FC<GadgetCardProps> = ({
  className,
  products,
  title,
  titleTx
}) => {
  const history = useHistory()
  const classes = useStyle()

  const handleOnNavigate = (id: number) => () => {
    history.push(`/item/${id}`)
  }

  return (
    <BlockContainer
      className={concatClassNames(className, classes.block)}
      justifyContent="flex-start"
    >
      <Text
        className={classes.title}
        preset="h2"
        color="white"
        text={title}
        tx={titleTx}
      />
      <Row wrap fullWidth className={classes.cards}>
        {products.data.map((card) => (
          <GadgetCard
            isSale={card.attributes.isDiscount}
            currency={card.attributes.currency}
            key={`card_${card.id}`}
            title={card.attributes.name}
            images={card.attributes.images}
            info={card.attributes.previewDesctription}
            price={card.attributes.price}
            salePrice={card.attributes.discountPrice}
            onClick={handleOnNavigate(card.attributes.id)}
          />
        ))}
      </Row>
    </BlockContainer>
  )
}
