import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { BlockContainer, Text, CategoryCard, Row } from '../../components'
import { Routes } from '../../routes/main.types'

import { useStyle } from './category.styles'
import { CategoryBlockProps } from './category.types'

export const CategoryBlock: FC<CategoryBlockProps> = ({ categories }) => {
  const history = useHistory()
  const classes = useStyle()

  const handleOnNavigate = (id: number) => () => {
    history.push(`${Routes.CATEGORY}/${id}`)
  }

  return (
    <BlockContainer className={classes.block} justifyContent="flex-start">
      <Text
        className={classes.title}
        preset="h2"
        color="white"
        text="Category"
        tx="category.title"
      />

      <Row wrap className={classes.cards}>
        {categories.data.map((card) => (
          <CategoryCard
            key={`card_${card.id}`}
            title={card.attributes.name}
            img={card.attributes.image.data.attributes.url}
            onClick={handleOnNavigate(card.id)}
          />
        ))}
      </Row>
    </BlockContainer>
  )
}
