import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.backgroundBlue,
    padding: '12px 0',
    position: 'sticky',

    top: 0,
    zIndex: 9
  },
  link: {
    textDecoration: 'none',
    '&:not(:first-child)': {
      marginLeft: 30
    }
  },
  linkText: {
    textTransform: 'uppercase'
  },
  linkContainer: {},
  logo: {},
  burger: {
    display: 'none'
  },
  burgerPos: {},
  locale: {
    cursor: 'pointer',
    minWidth: 128,
    justifyContent: 'space-evenly'
  },
  option: {
    background: 'transparent'
  },
  '@media screen and (max-width: 440px)': {
    container: {
      padding: '12px 0'
    },
    logo: {
      width: 162,
      height: 60,
      marginLeft: 8
    },
    linkContainer: {
      display: 'none'
    },
    link: {
      textDecoration: 'none',
      '&:not(:first-child)': {
        marginLeft: 30
      }
    },
    burger: {
      display: 'flex',
      marginLeft: 0
    },

    locale: {
      display: 'none'
    }
  }
}))
