import React, { FC } from 'react'

import { concatClassNames } from '../../utils'

import { useStyle } from './section.styles'
import { SectionProps } from './section.types'

export const Section: FC<SectionProps> = ({ id, className, children }) => {
  const classes = useStyle()

  return (
    <section id={id} className={concatClassNames(className, classes.container)}>
      {children}
    </section>
  )
}
