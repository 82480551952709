import React, { FC } from 'react'

import { Column } from '../column'

import { useStyle } from './content.styles'
import { ContentProps } from './content.types'

export const Content: FC<ContentProps> = ({
  className = '',
  preset = 'default',
  justifyContent = 'flex-start',
  alignItems,
  children
}) => {
  const classes = useStyle({ preset })
  return (
    <Column
      fullWidth
      fullHeight
      alignItems={alignItems}
      className={`${className} ${classes.container}`}
      justifyContent={justifyContent}
    >
      {children}
    </Column>
  )
}
