import { AuthApi } from './auth'
import { CategoryApi } from './caregory'
import { HomeApi } from './home'
import { ProductApi } from './product'
import { FooterApi } from './footer'

export enum ServerLocale {
  EN = 'en',
  UK = 'uk'
}

export type ItemWithAttributes<T> = {
  id: number
  attributes: T
}

export type EntityData<T> = {
  data: ItemWithAttributes<T>
}

export type ArrayData<T> = {
  data: ItemWithAttributes<T>[]
}

export type Image = {
  name: string
  alternativeText: string | null
  caption: string | null
  width: number
  height: number
  hash: string
  ext: string
  mime: string
  size: number
  url: string
  previewUrl: string
  provider: string
  providerMetadata: string | null
  createdAt: string
  updatedAt: string
}

export type ImageFormat = {
  thumbnail: Image
  small: Image
  medium: Image
  large: Image
}

export type ImageData = EntityData<ImageWithFormats>

export type ImagesData = ArrayData<ImageWithFormats>

export interface ImageWithFormats extends Image {
  formats: ImageFormat
}

export type Api = {
  setHeader: (key: string, prop: string) => void
  setToken: (token: string) => void
  auth: AuthApi
  home: HomeApi
  category: CategoryApi
  product: ProductApi
  footer: FooterApi
}
