import React, { createContext, FC, useContext, useState } from 'react'
import { IntlProvider } from 'react-intl'

import {
  LocaleContextValue,
  LocaleProviderProps,
  StructuredMessages
} from './locale.types'
import { ServerLocale } from '../../services'

const localeEN: StructuredMessages = require('../../i18n/en.json')
const localeUK: StructuredMessages = require('../../i18n/uk.json')

const localeData: Record<ServerLocale, Record<string, string>> = {
  // Default language
  [ServerLocale.EN]: localeEN,
  [ServerLocale.UK]: localeUK
}

export const localeNames: Record<ServerLocale, string> = {
  [ServerLocale.EN]: 'English',
  [ServerLocale.UK]: 'Ukrainian'
}

const defaultLocale = ServerLocale.UK

export const LocaleContext = createContext<LocaleContextValue>({
  localeData,
  currentLocale: ServerLocale.UK
})

export const LocaleProvider: FC<LocaleProviderProps> = ({
  locale: localeOverride,
  children
}) => {
  const locale = localeOverride || ServerLocale.EN
  const [currentLocale, changeCurrentLocale] = useState(locale)
  const [locales] = useState(localeData)

  const changeLocale = (nextLocale: ServerLocale) => {
    changeCurrentLocale(nextLocale)
  }

  const contextState = { localeData: locales, currentLocale, changeLocale }
  return (
    <LocaleContext.Provider value={contextState}>
      <IntlProvider
        defaultLocale={defaultLocale}
        locale={currentLocale}
        messages={locales[currentLocale]}
        key={locale}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

export const useLocale = () => useContext(LocaleContext)
