import React, { FC } from 'react'

import { concatClassNames } from '../../utils'

import { Column } from '../column'

import { useStyle } from './pattern-background.styles'
import { PolygonBackgroundProps } from './pattern-background.types'

export const PolygonBackground: FC<PolygonBackgroundProps> = ({
  className,
  children,
  ...props
}) => {
  const classes = useStyle()

  return (
    <Column
      fullWidth
      className={concatClassNames(className, classes.container)}
      {...props}
    >
      <Column className={classes.gradient}></Column>
      <Column className={classes.secondGradient}></Column>
      {children}
    </Column>
  )
}
