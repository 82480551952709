import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  title: {
    textTransform: 'capitalize',
    textAlign: 'left'
  },
  list: {
    marginTop: 24
  },
  item: {
    marginTop: 16
  }
})
