import React, { FC } from 'react'

import { Column, Row, Text } from '../../components'
import { EntityData } from '../../services'
import { Product } from '../../services/product/product.types'
import { useStyle } from './info-item.styles'
export type InfoItemProps = {
  className?: string
  product: EntityData<Product>
}
export const InfoItemBlock: FC<InfoItemProps> = ({ product }) => {
  const { fullDescription, secondDescription, thirdDescription } =
    product.data.attributes
  const classes = useStyle()

  return (
    <Column
      className={classes.bg}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Column
        className={classes.block}
        alignItems="start"
        justifyContent="start"
      >
        <Row>
          <Text
            color="bg"
            preset="h4"
            text={fullDescription}
            className={classes.text}
          />
        </Row>
        {secondDescription && (
          <Row>
            <Text
              color="bg"
              preset="h4"
              className={classes.text}
              text={secondDescription}
            />
          </Row>
        )}
        {thirdDescription && (
          <Row>
            <Text
              color="bg"
              preset="h4"
              className={classes.text}
              text={thirdDescription}
            />
          </Row>
        )}
      </Column>
    </Column>
  )
}
