import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  block: {
    padding: '32px 0',
    minHeight: 'calc(100vh - 320px)',
    minWidth: '100vw'
  },
  title: {
    textTransform: 'capitalize',
    width: 920
  },
  description: {
    marginTop: 20,
    textTransform: 'capitalize',
    width: 920
  },
  banner: {
    marginTop: 80
  },
  info: {
    marginTop: 80
  },
  row: {
    flexDirection: 'row'
  },
  cards: {
    marginTop: 32,
    gap: 80
  },
  orderedList: {},
  '@media screen and (max-width: 440px)': {
    block: {
      minHeight: 'auto',
      padding: '32px 0px'
    },
    title: {
      width: 'calc(100% - 32px)'
    },
    description: {
      marginTop: 12,
      width: 'calc(100% - 32px)'
    },
    orderedList: {
      marginTop: 40
    },
    info: {
      marginTop: 24
    },
    banner: {
      marginTop: 24
    }
  }
})
