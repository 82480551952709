import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.backgroundBlue,
    padding: '32px 16px',
    width: 'calc(100% - 32px)'
  },
  item: {
    marginTop: 16,
    textTransform: 'none'
  },
  contact: {
    alignSelf: 'stretch'
  },
  logo: {
    flex: 4
  },
  menu: {
    flex: 7
  },
  content: {},
  copyright: {
    marginTop: 16
  },
  bg: {
    backgroundColor: theme.colors.backgroundBlue
  },
  '@media screen and (max-width: 440px)': {
    container: {
      padding: '12px 0',
      minHeight: 375,
      width: '100%',
      backgroundColor: theme.colors.backgroundBlue
    },
    logo: {
      display: 'none'
    },
    menu: {
      padding: '0px 24px',
      alignItems: 'start',
      width: 195
    },
    content: {
      justifyContent: 'space-around'
    },
    contact: {
      marginTop: 27
    },
    copyright: {
      width: 228
    }
  }
}))
