import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  description: {
    marginTop: 32,
    padding: '0px 16px',
    textAlign: 'center'
  },
  gadgets: {}
})
